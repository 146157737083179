<template>
  <div class="test">
    <div class="p-col-12 p-md-12 p-lg-12 p-p-3 p-p-lg-0">
<!--        <Calendar v-model="date" view="month" dateFormat="mm/yy"-->
<!--                   :yearNavigator="true"></Calendar>-->
        <testReport />
<!--      <testTable :isRecommendations="false" />-->
    </div>
  </div>
</template>

<script>
// import personTable from "./personTable/personTable.vue";
//   import testTable from "./testTable/testTable.vue";
import {mapState} from "vuex";
import testReport from "./testReport/testReport.vue";

export default {
  name: "Test",
  data() {
    return {
      date: new Date()
    };
  },
  components: {
    testReport
    // testTable,
  },
  computed: {
    ...mapState(["user", "colel"]),

  }
};
</script>
