<template >

    <div
	    class="testReport"
	    key="componentKey"

    >
	   <div class=" p-grid p-jc-center">
		  <Button
			  v-if="dateConfirmation  && !isAdmin"
			  :label="confirmationLabel "
			  class="p-button-danger p-p-2 p-m-3"
			  @click="dateConfirmation = false"
		  />
	   </div >

	   <BlockUI :blocked="dateConfirmation && !isAdmin">

	   <DataTable
		   :loading="loading"
		   editMode="cell"
		   styles="text-align: right !important"
		   responsiveLayout="stack"
		   breakpoint="960px"
		   :value="testsSort"
		   class=" p-datatable-sm p-datatable-striped"
	   >
		  <template #header>
			 <div class="p-grid p-jc-between">

				<div class="p-col-12 p-md-6 p-lg-4 p-p-0"><Divider
					align="center"
					type="dashed"
				><h2 >
				    {{ $t(`testReport.title`) }}
				</h2 >
				      <div class="p-pt-4 p-mb-2 p-grid p-jc-center"
						 v-if="!isUnlockMonth" style="color: red;
		  text-align: center">
			 <h5><b > {{ $t('presenceReport.locked') }}</b ></h5 >
		  </div>
				</Divider >
				</div >
				<div
					class="p-col-12 p-md-6 p-lg-4 p-p-0"
				>
				    <Divider
					    align="center"
					    type="dashed"
				    >
					   <Calendar
						   v-model="date"
						   view="month"
						   dateFormat="mm/yy"
						   :yearNavigator="true"
					   ></Calendar >
				    </Divider >
				</div >
			 </div >
		  </template >
		  <Column
			  bodyStyle="text-align: right !important;"
			  :header="$t(`testReport.name`)"
		  >
			 <template #body="slotProps">
					{{ slotProps.data.last_name }}
					{{ slotProps.data.first_name }}
			 </template >
		  </Column >
		  <Column
			  bodyStyle="text-align: revert;"
			  field="oral_exam_grade"
			  :header="$t(`testReport.oral_exam_grade`)"
		  >
			 <template #body="slotProps">
				<div class="p-field-checkbox p-m-2">
				    <TriStateCheckbox
					    :disabled="!isUnlockMonth"
					    @input="onCellEdit($event, slotProps)"
					    v-model="slotProps.data.oral_exam_grade"
				    />
				    <label class="p-mr-1">{{
						  slotProps.data.oral_exam_grade === null ?
							  $t(`testReport.notReportYet`) :
							  slotProps.data.oral_exam_grade === true ?
								  $t(`testReport.past`) :
								  $t(`testReport.notPast`)
									 }}</label >
				</div >
			 </template >
		  </Column >
		  <Column
			  field="written_exam_grade"
			  :header="$t(`testReport.written_exam_grade`)"
		  >
			 <template #body="slotProps">
				<div class="p-d-flex p-flex-column">
				    <InputNumber
					    :disabled="!isUnlockMonth"
					    :max="100"
					    :min="0"
					    @input="onCellEdit($event, slotProps)"
					    :placeholder="$t(`testReport.written_exam_gradePlaceholder`)"
					    v-model.number="slotProps.data.written_exam_grade"
				    />
				    <Slider
					    :disabled="!isUnlockMonth"
					    @slideend="onCellEdit($event, slotProps)"
					    v-model="slotProps.data.written_exam_grade"
				    />
				</div >
			 </template >
		  </Column >
		  <Column
			  field="custom_message"
			  :header="$t(`testReport.custom_message`)"
		  >
			 <template #body="slotProps">
				<Textarea
					:disabled="!isUnlockMonth"
					class="p-inputtextarea p-inputtext p-component"
					:placeholder="$t(`testReport.custom_messagePlaceholder`)"
					:autoResize="true"
					@change="onCellEdit($event, slotProps)"
					v-model="slotProps.data.custom_message"
				></Textarea >
			 </template >
			</Column >
		</DataTable >
		  </BlockUI >

	</div >
</template >

<script >
import {mapState, mapActions, mapGetters} from "vuex";
import constants from '@/constants'
import {
  getTests,
  postTest
} from
      "./testReport";
import {dateToFullHebrew, monthToHebrew} from "@/utils/dateFormat.js";
import {canPostAll} from "@/utils/permissions.js";
import Vue from "vue";
import {sortByLastName} from "@/utils/sorts";

export default {
  name: "testReport",
  props: {},
  data() {
    return {
      dateConfirmation: true,
      is_manager_lock: true,
      is_owner_lock: true,
      loading: true,
      date: new Date(),
      testsOriginal: [],
      tests: [],
      online: true

    };
  },
  mounted() {
    this.getTests();
  },
  computed: {
    ...mapGetters(["isAdmin"]),
    isUnlockMonth() {

      return this.isAdmin
          ? !this.is_manager_lock
          : !(this.is_owner_lock || this.is_manager_lock)
    },
    testsSort() {
      // console.log("!@#", this.data)
      // console.log("!@#456", this.data.slice().sort((curr, next) =>
      //                                                  sortByLastName(curr,next)))
      return this.tests.slice().sort((curr, next) => sortByLastName(curr, next))
    },
    confirmationLabel() {
      return this.$t('testReport.confirmationLabel', {
        month:
            this.date.getMonth() + 1
      })
    },
    ...mapState(["user", "colel"]),
    hebrewDate() {
      return dateToFullHebrew(this.date)
    }
  },
  watch: {
    date() {
      this.dateConfirmation = true
      this.getTests()
    },
    colel(newC, oldC) {
      this.getTests()
    }
  },
  components: {},
  methods: {
    async onCellEdit(newValue, props) {
      try {
        await this.post(JSON.parse(JSON.stringify(props.data)))
        Vue.set(this.testsOriginal, props.index,
                JSON.parse(JSON.stringify(this.tests[props.index])));
      } catch (e) {
        console.log(e)
        Vue.set(this.tests, props.index,
                JSON.parse(
                    JSON.stringify(this.testsOriginal[props.index])));
        this.$toast.add({
                          severity: 'error',
                          summary: this.$t(
                              'testReport.postTestErrorTitle'),
                          detail:
                              this.$t('testReport.postTestErrorDetail'),
                          life: constants.LIFE_TOAST
                        });
      }

    },
    async getTests() {
      try {
        this.loading = true
        const {data: {tests, is_owner_lock, is_manager_lock} } = await
		  getTests(this.colel.colel.id, this.date)
        this.is_owner_lock = is_owner_lock
        this.is_manager_lock = is_manager_lock
        this.tests = JSON.parse(JSON.stringify(tests))
        this.testsOriginal = JSON.parse(JSON.stringify(tests));
        this.loading = false
      } catch (e) {
        this.$toast.add({
                          severity: 'error',
                          summary: this.$t(
                              'testReport.getPresencesErrorTitle'),
                          detail:
                              this.$t('testReport.getPresencesErrorDetail'),
                          life: constants.LIFE_TOAST
                        });
      }
    },

    async post(test) {
      // try {
      return postTest(test, this.colel.colel.id,
                      this.date
      )
      // } catch {
      //   console.log("ERRROR")
      //   this.$toast.add({
      //                     severity: 'error',
      //                     summary: this.$t('testReport.postErrorTitle'),
      //                     detail:
      //                         this.$t('testReport.postErrorDetail'),
      //                     life: constants.LIFE_TOAST
      //                   });
      // }
    }
  }
};
</script >

<style
	scoped
>

>>> .p-datatable .p-datatable-tbody > tr > td {
    text-align: right !important;
}

/*>>> .p-menu .p-menuitem-link .p-menuitem-text {*/
/*    padding-right: 10px;*/
/*}*/

/*>>> .p-button .p-button-icon-right {*/
/*    margin-right: 0.5rem;*/
/*}*/

/*>>> .p-menu.p-menu-overlay {*/
/*    !*background: aqua;*!*/
/*    top: none !important;*/
/*    left: none !important;*/
/*}*/

/*>>> .p-inputtext {*/
/*    !*width: -webkit-fill-available !important;*!*/
/*    width: 100% !important;*/
/*}*/

/*>>> .p-buttonset .p-button:last-of-type {*/
/*    border-top-right-radius: 0 !important;*/
/*    border-bottom-right-radius: 0 !important;*/
/*    border-top-left-radius: 3px !important;*/
/*    border-bottom-left-radius: 3px !important;*/
/*}*/

/*>>> .p-button .p-button-icon-left {*/
/*    margin-right: 0rem;*/
/*    margin-left: 0.5rem;*/
/*}*/

/*>>> .p-datatable-wrapper .p-datatable-striped {*/
/*    margin-right: 0rem;*/
/*    margin-left: 0.5rem;*/
/*}*/

/*>>> .p-buttonset .p-button:first-of-type {*/
/*    border-top-left-radius: 0 !important;*/
/*    border-bottom-left-radius: 0 !important;*/
/*    border-top-right-radius: 3px !important;*/
/*    border-bottom-right-radius: 3px !important;*/
/*}*/
</style >
